const yearEl = document.querySelector(".year");
yearEl!.textContent = new Date().getFullYear() as unknown as string;

// Make mobile navigation work

const btnNavEl = document.querySelector(".btn-mobile-nav");
const headerEl = document.querySelector(".header");

btnNavEl?.addEventListener("click", function () {
  //open close by click
  headerEl?.classList.toggle("nav-open");
  /* ------------------------ smooth scrolling animation ----------------------- */

  const allLinks = document.querySelectorAll("a:link");
  allLinks.forEach(function (link) {
    link.addEventListener("click", function (e) {
      e.preventDefault();
      const href = link.getAttribute("href");
      // Scroll to TOP
      if (href === "#")
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      //  Scroll to another section
      if (href !== "#" && href?.startsWith("#")) {
        const sectionEl = document.querySelector(href);
        sectionEl?.scrollIntoView({ behavior: "smooth" });
      }
      // Close mobile navigation
      if (link.classList.contains("main-nav-link"))
        headerEl?.classList.remove("nav-open");
    });
  });
});
// Sticky navigation
const sectionHeroEl = document.querySelector(".section-hero");
const observer = new IntersectionObserver(
  function (entries) {
    const ent = entries[0];
    if (!ent.isIntersecting) document.body.classList.add("sticky");
    else document.body.classList.remove("sticky");
  },
  {
    //In the viewport
    root: null,
    // 0% of hero section inside viewport
    threshold: 0,
    rootMargin: "-80px",
  }
);

observer.observe(sectionHeroEl!);
